import React from 'react';
import Spacing from '../Spacing';
import HeroStyle2 from '../Hero/HeroStyle2';
import Brands from '../Brands';
import Marquee from '../Marquee';
import Cta from '../Cta';
import SectionHeading from '../SectionHeading';
import Accordion from '../Accordion';
import PostCarousel from '../Slider/PostCarousel';
import TestimonialSlider from '../Slider/TestimonialSlider';
import Award from '../Award';
import VideoModal from '../VideoModal';
import ServiceSlider from '../Slider/ServiceSlider';
import AboutStyle2 from '../About/AboutStyle2';
import FunFact from '../FunFact';
import Button from '../Button';
import CaseStudy from '../CaseStudy';
import { pageTitle } from '../../helpers/PageTitle';
const servideData = [
  {
    iconSrc: '/images/marketing-agency/service_icon_1.svg',
    title: 'Hospitality Operations',
    subTitle:
      "We provide project management, construction oversight, guest satisfaction, housekeeping and cost containment programs.",
    btnText: 'See More',
    btnUrl: '/service/hospitality-operations',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_2.svg',
    title: 'Marketing Consulting',
    subTitle:
      "We provide market insights, marketing guidance, traffic insights, consumer and competition analysis and market trends identification services.",
    btnText: 'See More',
    btnUrl: '/service/marketing-consulting',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_3.svg',
    title: 'Accounting',
    subTitle:
      "We are able to conduct financial analysis, payroll management, generate profite statements and ensure tax obligations are met.",
    btnText: 'See More',
    btnUrl: '/service/accounting',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_4.svg',
    title: 'Brand Design',
    subTitle:
      "We put you ahead of other brands whilst ensuring your clientelle understands fully products and services provided by your company.",
    btnText: 'See More',
    btnUrl: '/service/brand-design',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_1.svg',
    title: 'Outsourcing Services',
    subTitle:
      "We are obligated to provide you with strategic alliances, labour cost reduction, ensure operation efficiency and provide tehnology knowledge.",
    btnText: 'See More',
    btnUrl: '/service/outsourcing-services',
  },
  {
    iconSrc: '/images/marketing-agency/service_icon_2.svg',
    title: 'Advisory services',
    subTitle:
      "We guide you best in identifying investment opportunities, offer operational guidance and advise best on hotel managemnt.",
    btnText: 'See More',
    btnUrl: '/service/advisory-services',
  },
  // {
  //   iconSrc: '/images/marketing-agency/service_icon_3.svg',
  //   title: 'Video Campaign',
  //   subTitle:
  //     "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
  //   btnText: 'See More',
  //   btnUrl: '/service/service-details',
  // },
  // {
  //   iconSrc: '/images/marketing-agency/service_icon_4.svg',
  //   title: 'Branding Design',
  //   subTitle:
  //     "A one provide moment. Interesting an a up se you side it all the and don't listen. Confident picture she one the what I nor least.",
  //   btnText: 'See More',
  //   btnUrl: '/service/service-details',
  // },
];
const funfactData = [
  { title: 'Happy Customers', number: '2k+' },
  { title: 'Work’s Completed', number: '965+' },
  { title: 'Consultants/Experts', number: '258' }
  // { title: 'Most Valuable Awards', number: '15' },
];
// const awardData = [
//   {
//     brand: 'Behance',
//     title: 'UI/UX des ign of the month',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'December 12, 2023',
//     awardImgUrl: '/images/creative-agency/award_img_1.svg',
//   },
//   {
//     brand: 'Awwwards',                     
//     title: 'CSS awards design',
//     subTitle:          
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'January 05, 2022',
//     awardImgUrl: '/images/creative-agency/award_img_2.svg',
//   },
//   {
//     brand: 'Google',
//     title: 'Website of the day',
//     subTitle:
//       'Accusamus et iusto odio dignissimos ducimus qui blanditiis fedarals praesentium voluptatum deleniti atque corrupti quos dolores',
//     date: 'March 20, 2021',
//     awardImgUrl: '/images/creative-agency/award_img_3.svg',
//   },
// ];
const testimonialData = [
  {
    text: 'ACES Consultancy Group completely transformed our operational processes. Their expert analysis revealed inefficiencies we didn’t know existed. Thanks to their strategies, we’ve reduced costs and improved service speed significantly. Highly recommended for any hospitality business looking to scale!',
    avatarName: 'Emily Parker',
    avatarDesignation: 'Owner at Oceanview Restaurant',
  },
  {
    text: 'Our guest satisfaction rates skyrocketed after working with ACES Consultancy Group. They helped us implement personalized guest experience strategies that resulted in glowing reviews and increased repeat bookings. The results speak for themselves!',
    avatarName: 'Michael Davis',
    avatarDesignation: 'General Manager at Luxe Hotel',
  },
  {
    text: 'The training programs ACES provided were invaluable. Our staff performance improved dramatically, and we’ve seen a noticeable uptick in customer satisfaction. Their hands-on approach and industry expertise make them a top choice for hospitality consulting.',
    avatarName: 'Sarah Lee',
    avatarDesignation: 'Operations Manager at Riverside Café',
  },
  {
    text: 'ACES Consultancy Group helped us integrate cutting-edge technology into our operations, streamlining everything from reservations to inventory management. Their solutions have saved us time and money, and the team was an absolute pleasure to work with.',
    avatarName: 'David Nguyen',
    avatarDesignation: 'CEO at Urban Stay Hotels',
  },
  {
    text: 'We struggled with marketing until ACES Consultancy Group stepped in. They redefined our brand identity, and our customer engagement has never been higher. They’re truly experts in hospitality marketing and helped us achieve our growth goals.',
    avatarName: 'Laura Bennett',
    avatarDesignation: 'Marketing Director at Sunset Resort',
  },
  {
    text: 'Working with ACES Consultancy Group was a game-changer for us. They helped us streamline our service operations and even introduced tech-driven solutions that improved our overall efficiency. We’re now operating at a whole new level.',
    avatarName: 'Daniel Turner',
    avatarDesignation: 'Owner at Turner’s Diner',
  },
  {
    text: 'Their team worked closely with us to address our unique challenges, especially in guest management. Thanks to their expertise, our business now runs smoother, and our customers are happier than ever. Highly recommend ACES Consultancy Group!',
    avatarName: 'Sophia Martinez',
    avatarDesignation: 'Co-Founder at The Lakeside Inn',
  },
];

const caseStudyData = [
  {
    thumbnailSrc: '/images/marketing-agency/case_study_1.jpg',
    title: 'Operational Efficiency Consulting',
    category: 'Operations',
    number: '01',
    href: '/case-study-details',
  },
  {
    thumbnailSrc: '/images/marketing-agency/case_study_2.jpg',
    title: 'Staff Training and Development',
    category: 'Training',
    number: '02',
    href: '/case-study-details',
  },
  {
    thumbnailSrc: '/images/marketing-agency/case_study_3.jpg',
    title: 'Guest Experience Enhancement',
    category: 'Customer Experience',
    number: '03',
    href: '/case-study-details',
  },
  {
    thumbnailSrc: '/images/marketing-agency/case_study_4.jpg',
    title: 'Technology Integration and Automation',
    category: 'Technology',
    number: '04',
    href: '/case-study-details',
  },
];


const postData = [
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How to keep fear from ruining your art business with confident',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Artistic mind will be great for creation anything',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'AI will take over all job for human within few years',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Your agency need to replace some artistic mind people',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_1.jpeg',
    title: 'How to keep fear from ruining your art business with confident',
    date: '07 Mar 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_2.jpeg',
    title: 'Artistic mind will be great for creation anything',
    date: '22 Apr 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_3.jpeg',
    title: 'AI will take over all job for human within few years',
    date: '13 May 2023',
    url: '/blog/blog-details',
  },
  {
    thumbnailSrc: '/images/creative-agency/post_4.jpeg',
    title: 'Your agency need to replace some artistic mind people',
    date: '15 Mar 2023',
    url: '/blog/blog-details',
  },
];
const faqData = [
  {
    title: '01. What sets ACES Consultancy Group apart from other consulting firms',
    content:
      'ACES Consultancy Group distinguishes itself through our deep industry expertise, personalized approach, and a strong network of seasoned professionals. We focus on delivering tailored solutions that address the unique challenges of each client, ensuring measurable success and long-term growth.',
  },
  {
    title: '02. How does ACES ensure the quality of its consulting services?',
    content:
      'We ensure quality by engaging industry experts with proven track records, implementing best practices, and continuously monitoring the progress of each project. Our commitment to excellence is reflected in the consistent results we achieve for our clients.',
  },
  {
    title: '03. Can ACES Consultancy Group work with international clients?',
    content:
      'Yes, ACES Consultancy Group has experience working with clients across different regions. Our team is well-versed in global hospitality trends and can provide consulting services tailored to the specific needs of international markets.',
  },
  {
    title: '04. What is the process for engaging ACES Consultancy Group for a project?',
    content:
      'Engaging ACES starts with an initial consultation to understand your needs and objectives. We then develop a customized plan, outlining the scope of services, timelines, and deliverables. Our collaborative approach ensures that we work closely with you throughout the project to achieve the desired outcomes.',
  },
  {
    title: '05. How does ACES Consultancy Group stay updated with the latest trends in the hospitality industry?',
    content:
      'We continually research and monitor industry trends, participate in hospitality conferences, and collaborate with global experts to ensure that we are always providing our clients with the most up-to-date and innovative solutions.',
  },
];
const brandList = [
  {
    logoSrc: '/images/marketing-agency/brand_1.svg',
    logoAlt: 'Brand',
  },
  {
    logoSrc: '/images/marketing-agency/brand_2.svg',
    logoAlt: 'Brand',
  },
  {
    logoSrc: '/images/marketing-agency/brand_3.svg',
    logoAlt: 'Brand',
  },
  {
    logoSrc: '/images/marketing-agency/brand_4.svg',
    logoAlt: 'Brand',
  },
];
const brandListDark = [
  {
    logoSrc: '/images/marketing-agency/brand_1_dark.svg',
    logoAlt: 'Brand',
  },
  {
    logoSrc: '/images/marketing-agency/brand_2_dark.svg',
    logoAlt: 'Brand',
  },
  {
    logoSrc: '/images/marketing-agency/brand_3_dark.svg',
    logoAlt: 'Brand',
  },
  {
    logoSrc: '/images/marketing-agency/brand_4_dark.svg',
    logoAlt: 'Brand',
  },
];

export default function MarketingAgencyPage({ darkMode }) {
  pageTitle('ACES HCG');
  return (
    <>
      <HeroStyle2
        miniTitle="A Pool of Hospitality experts"
        title="Unleash your hospitality business'full potential with tailored expert guidance and innovative solutions."
        subTitle="We specialize in delivering comprehensive consulting, management and outsourcing for the hospitality industry."
        thumbnailSrc="/images/marketing-agency/hiro_img.jpg"
        mikeIcon={
          darkMode ? '/images/icons/mike_dark.svg' : '/images/icons/mike.svg'
        }
      />
      <section className="cs_p76_full_width" id="service">
        <Spacing lg="143" md="75" />
        <SectionHeading title="Services we provide" subTitle="Our Services" />
        <Spacing lg="85" md="45" />
        <ServiceSlider data={servideData} />
      </section>
      <section>
        <div className="cs_height_150 cs_height_lg_75" />
        <AboutStyle2
          thumbnailSrc1="/others-image/about_img_1.jpg"
          thumbnailSrc2="/others-image/about_img_2.jpg"
          uperTitle="Who We Are"
          title="A Hospitality Consultancy Group Company"
          subTitle="Our mission is to empower hotels, resorts, residences, conservancies and service apartments with innovative strategies and practical solutions, 
          especially for properties that need revitalization. We patner with owners and experts to craft customized plans that drive growth and enhance operational efficiency."
          featureList={[
            'Comprehensive Hospitality Solutions',
            'Expert Advisory & Strategic Planning',
            'Results-Driven Approach',
          ]}
          btnText="Learn More"
          btnUrl="/about"
        />
      </section>
      <div className="container">
        <Spacing lg="125" md="70" />
        <FunFact data={funfactData} />
      </div>
      <section>
        <Spacing lg="118" md="70" />
        <div className="container">
          <SectionHeading
            title="Real world solutions successful <br/>contracts by Us"
            subTitle="Case Study"
          />
          <Spacing lg="85" md="45" />
        </div>
        <CaseStudy data={caseStudyData} />
        <Spacing lg="100" md="60" />
        <div className="container">
          <div className="text-center">
            <Button btnText="More Case Study" btnUrl="/" />
          </div>
        </div>
      </section>
      <div className="container">
        <Spacing lg="126" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=nRj-c2k9drM"
          bgUrl="/images/creative-agency/hero_video_bg_1.jpg"
          title="Our Hospitality Consultancy Company <br /> is dedicated to turning <br /> your business vision into a successful reality."
        />
      </div>
      <TestimonialSlider
        layeredImages={[
          // '/images/marketing-agency/layer_img_1.png',
          // '/images/marketing-agency/layer_img_2.png',
          // '/images/marketing-agency/layer_img_3.png',
          // '/images/marketing-agency/layer_img_4.png',
          // '/images/marketing-agency/layer_img_5.png',
        ]}
        data={testimonialData}
      />
      <section className="cs_primary_bg cs_shape_animation_2">
        <Spacing lg="143" md="75" />
        <div className="cs_shape_1 position-absolute">
          <svg
            width={65}
            height={64}
            viewBox="0 0 65 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g opacity="0.5">
              <path
                d="M62.4554 25.9314C55.6838 19.6081 40.1618 12.4752 32.1637 20.1537C41.7609 21.9206 53.2379 29.2392 48.3751 39.1677C45.1712 45.7019 38.7353 45.7177 33.3337 41.995C27.338 37.8739 25.7108 31.2667 27.4596 24.5962C26.5312 24.5866 25.6039 24.6605 24.6889 24.8172C9.80991 27.7447 14.0713 47.6353 20.9187 55.948C22.4528 57.8045 19.7488 60.3159 18.1393 58.4837C7.86403 46.8126 6.49349 23.0691 25.5532 19.9295C26.8892 19.7254 28.2446 19.6801 29.5912 19.7945C36.9845 9.42053 56.5698 17.4866 64.055 24.4366C65.1096 25.4175 63.4831 26.8926 62.4554 25.9314ZM33.9938 39.0327C38.3927 42.4636 44.2429 40.8527 44.3919 34.8698C44.6036 28.2263 35.7464 25.0921 29.1457 24.655C27.1454 29.9313 29.4427 35.4836 33.9938 39.0327Z"
                fill="#4F4747"
              />
            </g>
          </svg>
        </div>
        <div className="container">
          {/* <SectionHeading
            title="Our prize achievement"
            subTitle="Awards"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          {/* <Award data={awardData} /> */}
        </div>
        <Spacing lg="150" md="80" />
      </section> 
      {/* <section className="cs_p76_full_width">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Some recent news" subTitle="Our Blog" />
          <Spacing lg="85" md="45" />
        </div>
        <PostCarousel data={postData} />
      </section> */}
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="60" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="Is there a specific project or goal that you have in mind?"
            btnText="Contact Us"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <Spacing lg="135" md="70" />
      <Marquee text="Strategy - Operations - Staff Training - Customer Experience - Business Growth" />
      <Spacing lg="84" md="50" />
      <div className="container">
        <Brands data={darkMode ? brandListDark : brandList} />
      </div>
      <Spacing lg="135" md="80" />
    </>
  );
}
