import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import CtaStyle2 from '../Cta/CtaStyle2';
import AboutStyle7 from '../About/AboutStyle7';
import AboutStyle8 from '../About/AboutStyle8';
import { pageTitle } from '../../helpers/PageTitle';

export default function CaseStudyDetailsPage() {
  pageTitle('Case Study Details');
  return (
    <>
      <>
  <Spacing lg="70" md="70" />
  <Spacing lg="140" md="80" />
  <SectionHeadingStyle3
    title="Operational Efficiency <br/>Improvement"
    subTitle="Consultancy & Strategy"
    variant="text-center"
  />
  <Spacing lg="75" md="60" />

  <div className="container">
    <img
      className="cs_casestudy_details_thumb cs_radius_15"
      src="/images/others/case_1.jpeg"
      alt="Thumb"
    />
    <div className="cs_height_100 cs_height_lg_60" />
    <ul className="cs_case_study_info cs_mp0">
      <li>
        <p className="mb-0">Client</p>
        <h3 className="cs_fs_21 mb-0 cs_semibold">Martin Kivuli</h3>
      </li>
      <li>
        <p className="mb-0">Location</p>
        <h3 className="cs_fs_21 mb-0 cs_semibold">Machakos County</h3>
      </li>
      <li>
        <p className="mb-0">Budgets</p>
        <h3 className="cs_fs_21 mb-0 cs_semibold">kes 75,000</h3>
      </li>
      <li>
        <p className="mb-0">Completed</p>
        <h3 className="cs_fs_21 mb-0 cs_semibold">12 April 2023</h3>
      </li>
    </ul>
    <div className="cs_height_100 cs_height_lg_60" />
    <div className="row align-items-center cs_gap_y_35">
      <div className="col-lg-5">
        <img
          src="/images/others/case_2.jpeg"
          alt="Thumb"
          className="cs_radius_15 w-100"
        />
      </div>
      <div className="col-lg-6 offset-lg-1">
        <h2 className="cs_fs_50">Project Brief:</h2>
        <div className="cs_height_15 cs_height_lg_15" />
        <p>
          Our client, Andreo Bowla, approached us with the challenge of
          streamlining their operational processes. They were facing
          inefficiencies that hindered their ability to scale operations and
          maintain consistency across various business units.
        </p>
        <p>
          We conducted a thorough analysis of their current workflow and
          identified bottlenecks in communication, task management, and
          resource allocation. Leveraging our expertise in operational
          consulting, we restructured their processes using lean management
          principles and implemented automation to enhance productivity and
          reduce manual overhead.
        </p>
        <div className="cs_height_20 cs_height_lg_10" />
        <p className="mb-0">
          Website:{' '}
          <a href="/" className="cs_accent_color">
            www.aceshcg.com
          </a>
        </p>
      </div>
    </div>
  </div>
  <Spacing lg="150" md="80" />
  <AboutStyle7
    title="Challenging part:"
    subTitle="We had to address inefficiencies in resource management and streamline operational workflows while ensuring minimal downtime for ongoing business operations."
    featureList={[
      { number: '01', text: 'Workflow Automation' },
      { number: '02', text: 'Resource Optimization' },
      { number: '03', text: 'Task Management Improvement' },
      { number: '04', text: 'Cross-Team Communication' },
      { number: '05', text: 'Data-Driven Decision Making' },
    ]}
    thumbnailSrc="/images/others/case_3.jpeg"
  />
  <Spacing lg="150" md="80" />
  <AboutStyle8
    thumbnailSrc="/images/others/case_4.jpeg"
    title="Result Overview:"
    subTitle="Our solutions resulted in a significant improvement in operational efficiency. The client reported enhanced workflow management, better task prioritization, and overall increased productivity."
    grothData={[
      { title: 'Operational Efficiency', percentage: '80' },
      { title: 'Resource Utilization', percentage: '85' },
      { title: 'Task Completion Speed', percentage: '90' },
    ]}
  />
  <Spacing lg="140" md="70" />
  <CtaStyle2
    title="Looking to improve your operational efficiency?"
    btnText="Contact Us"
    btnUrl="/contact"
  />
  <div className="cs_height_150 cs_height_lg_80" />
</>

      <Spacing lg="140" md="70" />
      <CtaStyle2
        title="Is there a specific project or goal <br />that you have in mind?"
        btnText="Send Message"
        btnUrl="/contact"
      />
      <div className="cs_height_150 cs_height_lg_80" />
    </>
  );
}
