import React, { useState } from 'react';
import axios from 'axios';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';
import { Icon } from '@iconify/react';

export default function ContactPage() {
  pageTitle('Contact');

  // State to manage form data
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  // State to manage submission feedback
  const [feedback, setFeedback] = useState({ success: null, message: '' });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3001/send-email', formData);
      if (response.data.success) {
        setFeedback({ success: true, message: 'Email sent successfully!' });
      } else {
        setFeedback({ success: false, message: 'Failed to send email.' });
      }
    } catch (error) {
      console.error('Error sending email:', error);
      setFeedback({ success: false, message: 'Error sending email.' });
    }
  };

  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="Get in touch for more <br/>information and support"
        subTitle="Contact"
        shape="shape_6"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row align-items-center cs_gap_y_45">
            <div className="col-lg-6">
              <h2 className="cs_fs_50">
                Come & visit <br />
                our place!
              </h2>
              <div className="cs_height_55 cs_height_lg_30" />
              <ul className="cs_mp0 cs_contact_info">
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Email:</h3>
                  <p className="mb-0">info@aceshcg.com</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Phone:</h3>
                  <p className="mb-0">+254 784 727 227</p>
                </li>
                <li>
                  <h3 className="cs_fs_29 cs_semibold">Address:</h3>
                  <p className="mb-0">Nairobi, Kenya</p>
                </li>
              </ul>
            </div>
            <div className="col-lg-6">
              <div className="cs_contact_form_wrap">
                <div className="cs_gray_bg_3 cs_contact_form_bg" />
                <form className="cs_contact_form" onSubmit={handleSubmit}>
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Your full name
                  </label>
                  <input
                    placeholder="Type your name"
                    type="text"
                    name="name"
                    className="cs_form_field"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Give valid email
                  </label>
                  <input
                    placeholder="Type your email address"
                    type="email"
                    name="email"
                    className="cs_form_field"
                    value={formData.email}
                    onChange={handleChange}
                    required
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Phone number
                  </label>
                  <input
                    placeholder="Type your phone number"
                    type="tel"
                    name="phone"
                    className="cs_form_field"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <label className="cs_fs_21 cs_semibold cs_primary_color">
                    Project brief
                  </label>
                  <textarea
                    cols={30}
                    rows={5}
                    name="message"
                    className="cs_form_field"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                  <div className="cs_height_38 cs_height_lg_25" />
                  <button type="submit" className="cs_btn cs_style_1">
                    Submit Message{' '}
                    <span>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                      <i>
                        <Icon icon="fa6-solid:arrow-right" />
                      </i>
                    </span>
                  </button>
                </form>

                {/* Feedback Message */}
                {feedback.message && (
                  <p className={`cs_fs_21 ${feedback.success ? 'text-success' : 'text-danger'}`}>
                    {feedback.message}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="cs_height_0 cs_height_lg_80" />
      </section>
      <div className="cs_map">
        <iframe
          id="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6994046346213!2d36.73667471215997!3d-1.3569689357050914!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f05c6488c6797%3A0x66f2c86fd2ccad77!2sCultiva%20Farm%20Kenya!5e0!3m2!1sen!2ske!4v1727612878449!5m2!1sen!2ske"
          allowFullScreen
          title="Google Map"
        />
      </div>
    </>
  );
}
