import React from 'react';
import { useState } from 'react';
import PricingTable from '.';

export default function PricingTableList() {
  const [tab, setTab] = useState('monthly');
  return (
    <div className="cs_tabs cs_style1">
      <ul className="cs_tab_links cs_style1 cs_mp0 cs_pricing_control">
        <li
          className={tab === 'monthly' ? 'active' : ''}
          onClick={() => setTab('monthly')}
        >
          <span>Monthly</span>
          <span className="cs_switch"></span>
        </li>
        <li
          className={tab === 'yearly' ? 'active' : ''}
          onClick={() => setTab('yearly')}
        >
          <span>Yearly</span>
        </li>
      </ul>
      <div className="row cs_gap_y_30">
        {/* Silver Package */}
        <div className="col-lg-4">
          {tab === 'monthly' && (
            <PricingTable
              title="Silver"
              subTitle="A perfect package for startups or small businesses looking to enhance their operations."
              price="39,900"
              currency="KES "
              timeline="monthly"
              features={[
                'Basic operational consulting',
                'Branding consultancy',
                'Access to 24/7 support',
                'Monthly performance reviews',
              ]}
              btnText="Choose Package"
              btnLink="/"
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Silver"
              subTitle="A perfect package for startups or small businesses looking to enhance their operations."
              price="49,900"
              currency="KES "
              timeline="yearly"
              features={[
                'Basic operational consulting',
                'Branding consultancy',
                'Access to 24/7 support',
                'Monthly performance reviews',
              ]}
              btnText="Choose Package"
              btnLink="/"
            />
          )}
        </div>
        {/* Gold Package */}
        <div className="col-lg-4">
          {tab === 'monthly' && (
            <PricingTable
              title="Gold"
              subTitle="Ideal for growing businesses seeking advanced solutions for scaling."
              price="59,900"
              currency="KES "
              timeline="monthly"
              features={[
                'Advanced operational consulting',
                'Brand development services',
                'Marketing strategy implementation',
                'Staff training and development',
                'Quarterly performance reviews',
              ]}
              btnText="Choose Package"
              btnLink="/"
              popular
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Gold"
              subTitle="Ideal for growing businesses seeking advanced solutions for scaling."
              price="79,900"
              currency="KES "
              timeline="yearly"
              features={[
                'Advanced operational consulting',
                'Brand development services',
                'Marketing strategy implementation',
                'Staff training and development',
                'Quarterly performance reviews',
              ]}
              btnText="Choose Package"
              btnLink="/"
              popular
            />
          )}
        </div>
        {/* Platinum Package */}
        <div className="col-lg-4">
          {tab === 'monthly' && (
            <PricingTable
              title="Platinum"
              subTitle="Comprehensive solutions for large enterprises, including all premium services."
              price="99,900"
              currency="KES "
              timeline="monthly"
              features={[
                'Complete business consulting',
                'Full brand development',
                'Dedicated marketing consultancy',
                'Technology integration services',
                'Staff training and outsourcing solutions',
                'Monthly performance and strategy reviews',
                'Priority 24/7 support',
              ]}
              btnText="Choose Package"
              btnLink="/"
            />
          )}
          {tab === 'yearly' && (
            <PricingTable
              title="Platinum"
              subTitle="Comprehensive solutions for large enterprises, including all premium services."
              price="129,900"
              currency="KES "
              timeline="yearly"
              features={[
                'Complete business consulting',
                'Full brand development',
                'Dedicated marketing consultancy',
                'Technology integration services',
                'Staff training and outsourcing solutions',
                'Monthly performance and strategy reviews',
                'Priority 24/7 support',
              ]}
              btnText="Choose Package"
              btnLink="/"
            />
          )}
        </div>
      </div>
    </div>
  );
}
